import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useWorkplaceList() {
  // Use toast
  const toast = useToast()

  const refWorkplaceListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'Nome' },
    { key: 'phone', label: 'Telefone' },
    { key: 'city', label: 'Cidade' },
    { key: 'duties', label: 'Serviços' },
    { key: 'actions', label: 'Ações' },
  ]
  const perPage = ref(10)
  const totalWorkplaces = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refWorkplaceListTable.value ? refWorkplaceListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalWorkplaces.value,
    }
  })

  const refetchData = () => {
    refWorkplaceListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchWorkplaces = (ctx, callback) => {
    store
      .dispatch('app-workplace/fetchWorkplaces', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
      })
      .then(response => {
        const { data: workplaces, meta: { pagination: { total } } } = response.data
        callback(workplaces)
        totalWorkplaces.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao buscar lista de unidades de trabalho',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const phoneMask = v => {
    let r = v.replace(/\D/g, '')
    r = r.replace(/^0/, '')
    if (r.length > 10) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3')
    } else if (r.length > 5) {
      r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3')
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2')
    } else {
      r = r.replace(/^(\d*)/, '($1')
    }
    return r
  }

  return {
    fetchWorkplaces,
    tableColumns,
    perPage,
    currentPage,
    totalWorkplaces,
    dataMeta,
    perPageOptions,
    searchQuery,

    refWorkplaceListTable,

    refetchData,

    phoneMask,
  }
}
