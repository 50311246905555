<template>
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="2"
          >
            <label for="begin-date">Início em</label>
            <b-form-datepicker
              id="begin-date"
              v-model="beginDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="pt-BR"
              @input="loadReport"
            />
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <label for="end-date">Fim em</label>
            <b-form-datepicker
              id="end-date"
              v-model="endDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="pt-BR"
              @input="loadReport"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <label for="workplace-id">Unidades</label>
            <v-select
              id="workplace-id"
              v-model="workplaceId"
              name="workplace-id"
              :options="workplaceOptions"
              label="label"
              item-value="value"
              item-text="label"
              @input="loadReport"
            >
              <span slot="no-options">Nenhum local encontrado.</span>
            </v-select>
          </b-col>
          <b-col
            cols="12"
            md="5"
          >
            <div class="d-flex w-100">
              <div class="flex-grow-1">
                <label for="physician-user-id">Médicos</label>
                <v-select
                  id="physician-user-id"
                  v-model="physicianUserId"
                  name="physician-user-id"
                  :options="physicianUserOptions"
                  label="label"
                  item-value="value"
                  item-text="label"
                  @input="loadReport"
                >
                  <span slot="no-options">Nenhum médico encontrado.</span>
                </v-select>
              </div>
              <div class="pt-2 ml-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon"
                  :disabled="reports.length === 0"
                  @click="downloadReport"
                >
                  Exportar
                  <feather-icon
                    icon="DownloadIcon"
                    size="16"
                  />
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <!-- Workplaces -->
            <div v-if="reports.length === 0" class="m-5">
              <h4 class="text-center w-100">
                Não há registros
              </h4>
            </div>
            <app-collapse accordion type="border">
              <app-collapse-item v-for="(report, i) in reports" :key="`report-${i}`" :title="report.name || ''">
                <template v-slot:header>
                  <div class="d-flex justify-content-between w-100">
                    <h3>{{ report.name }}</h3>
                    <div class="text-right">
                      <div>
                        <span class="text-muted">Valor do Plantão</span>
                        <span class="collapse-title"> {{ currency(report.amount_physician_duty) }}</span>
                      </div>
                      <div>
                        <span class="text-muted">Recebido</span>
                        <span class="collapse-title"> {{ currency(report.discounted_amount) }}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <!-- Physician -->
                <h6>Médicos</h6>
                <app-collapse accordion>
                  <app-collapse-item v-for="(physician,index) in report.physicians" :key="`physician-${i}-${index}`" :title="physician.name || ''" class="panel-physician">
                    <template v-slot:header>
                      <div class="d-flex justify-content-between w-100">
                        <div>
                          <h3>{{ physician.name }}</h3>
                          <span class="text-muted">CPF </span>
                          <span class="collapse-title">{{ physician.cpf }}</span>
                        </div>
                        <div class="text-right">
                          <div>
                            <span class="text-muted">Valor do Plantão</span>
                            <span class="collapse-title"> {{ currency(physician.total_amount) }}</span>
                          </div>
                          <div>
                            <span class="text-muted">Recebido</span>
                            <span class="collapse-title"> {{ currency(physician.total_discounted_amount) }}</span>
                          </div>
                        </div>
                      </div>
                    </template>
                    <!-- Physician Duties -->
                    <h6>Plantões</h6>
                    <b-list-group flush>
                      <b-list-group-item v-for="(duty, dutyIndex) in physician.physician_duty" :key="`duty-${i}-${index}-${dutyIndex}`" :title="duty.workplace_id">
                        <div class="d-flex">
                          <div class="d-flex flex-grow-1 text-nowrap">
                            <div>
                              <div class="text-right">
                                <span class="text-muted">Início</span>
                                <span class="collapse-title"> {{ formatDate(duty.start_date) }}</span>
                              </div>
                              <div class="text-right">
                                <span class="text-muted">Fim</span>
                                <span class="collapse-title"> {{ formatDate(duty.end_date) }}</span>
                              </div>
                            </div>
                            <div class="d-flex ml-1">
                              <div>
                                <div class="text-left duty-date">
                                  <span class="text-muted">Sincronizado</span>
                                  <span
                                    class="collapse-title"
                                    :class="{'invisible':!duty.synchronized_at}"
                                  > {{ duty.synchronized_at ? formatDate(duty.synchronized_at) : '00/00/0000 00:00' }}</span>
                                </div>
                                <div class="text-left duty-date">
                                  <span class="text-muted">Antecipado</span>
                                  <span class="collapse-title"> {{ formatDate(duty.anteciped_at) }}</span>
                                </div>
                              </div>
                              <div class="ml-1">
                                <div class="text-left duty-date">
                                  <span class="text-muted">Pago</span>
                                  <span
                                    class="collapse-title"
                                    :class="{'invisible':!duty.paid_at}"
                                  > {{ duty.paid_at ? formatDate(duty.paid_at) : '00/00/0000 00:00' }}</span>
                                </div>
                                <div class="text-left duty-date">
                                  <span class="text-muted">Operação</span>
                                  <span class="collapse-title"> {{ duty.operation_cod }}</span>
                                </div>
                              </div>
                              <div class="ml-1">
                                <div class="text-left duty-date">
                                  <span class="text-muted">Tipo</span>
                                  <span class="collapse-title"> {{ duty.person_type || 'PF' }}</span>
                                </div>
                              </div>
                              <div v-if="duty.physician_company_cnpj" class="ml-1">
                                <div class="text-left duty-date">
                                  <span class="text-muted">Empresa</span>
                                  <span class="collapse-title"> {{ duty.physician_company_name }}</span>
                                </div>
                                <div class="text-left duty-date">
                                  <span class="text-muted">CNPJ</span>
                                  <span class="collapse-title"> {{ formatCnpj(duty.physician_company_cnpj) }}</span>
                                </div>
                              </div>
                              <div class="ml-1">
                                <div class="text-left">
                                  <span
                                    class="collapse-title"
                                  >
                                    <button
                                      v-if="duty.paid_at"
                                      class="btn btn-sm btn-link text-success btn-contract p-0 ml-0"
                                      @click="downloadPaymentVoucher(duty.contract_id)"
                                    >
                                      Comprovante
                                      <feather-icon
                                        icon="DownloadIcon"
                                        size="14"
                                      />
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="text-left">
                            <div>
                              <span class="text-muted">Valor do Plantão</span>
                              <span class="collapse-title"> {{ currency(duty.amount_physician_duty) }}</span>
                            </div>
                            <div>
                              <span class="text-muted">Recebido</span>
                              <span class="collapse-title"> {{ currency(duty.contract_amount) }}</span>
                            </div>
                          </div>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                  </app-collapse-item>
                </app-collapse>
              </app-collapse-item>
            </app-collapse>
          </b-col>
        </b-row>
      </div>

    </b-card>
  </div>
</template>

<script>
import {
  format, parseISO, startOfMonth, endOfMonth,
} from 'date-fns'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import {
  ref, onUnmounted,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BListGroup, BListGroupItem, BFormDatepicker, BButton,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { formatCnpj, formatCpf } from '@core/utils/utils'
import reportStoreModule from '../reportStoreModule'
import useReport from './useReport'

export default {
  directives: {
    Ripple,
  },
  components: {
    AppCollapse,
    AppCollapseItem,
    BCard,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BFormDatepicker,
    vSelect,
    BButton,
  },
  methods: { formatCnpj },
  setup() {
    const REPORTS_STORE_MODULE_NAME = 'app-reports'
    // Register module
    if (!store.hasModule(REPORTS_STORE_MODULE_NAME)) store.registerModule(REPORTS_STORE_MODULE_NAME, reportStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORTS_STORE_MODULE_NAME)) store.unregisterModule(REPORTS_STORE_MODULE_NAME)
    })

    const formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
    const currency = value => formatter.format(value / 100)
    const formatDate = value => format(parseISO(value), 'dd/MM/yyyy HH:mm')
    const reports = ref([])
    const beginDate = ref(format(startOfMonth(new Date()), 'yyyy-MM-dd'))
    const endDate = ref(format(endOfMonth(new Date()), 'yyyy-MM-dd'))
    const physicianUserId = ref('')
    const workplaceId = ref('')
    const physicianUserOptions = ref([])
    const workplaceOptions = ref([])
    const workplace = ref({
      address: {},
      duties: {
        6: '',
        12: '',
        24: '',
      },
    })

    const getReportParams = () => ({
      begin: beginDate.value,
      end: endDate.value,
      workplace: workplaceId.value ? workplaceId.value.value : '',
      user: physicianUserId.value ? physicianUserId.value.value : '',
    })

    const loadReport = () => {
      store.dispatch('app-reports/fetchReport', getReportParams())
        .then(response => {
          reports.value = response.data.data.workplace ?? []
        })
    }
    loadReport()

    const downloadReport = () => {
      const params = getReportParams()
      store.dispatch('app-reports/downloadReport', params)
        .then(response => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `PlantoesAdiantados_${params.begin}_${params.end}.xlsx`
          link.click()
          URL.revokeObjectURL(link.href)
        })
    }

    const downloadPaymentVoucher = contractId => {
      store.dispatch('app-reports/exportPaymentVoucher', { contractId })
        .then(response => {
          const downloadLink = document.createElement('a')
          downloadLink.target = '_blank'
          downloadLink.download = `comprovante_${contractId}.pdf`
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const URL = window.URL || window.webkitURL
          const downloadUrl = URL.createObjectURL(blob)
          downloadLink.href = downloadUrl
          document.body.append(downloadLink)
          downloadLink.click()
          document.body.removeChild(downloadLink)
          URL.revokeObjectURL(downloadUrl)
        })
    }

    store.dispatch('app-reports/fetchPhysicians', { perPage: 99999999 })
      .then(response => {
        physicianUserOptions.value = response.data.data.map(physician => ({
          value: physician.user_id,
          label: `${physician.name} - ${formatCpf(physician.cpf, true)}`,
        }))
      })

    store.dispatch('app-reports/fetchWorkplaces', { perPage: 99999999 })
      .then(response => {
        workplaceOptions.value = response.data.data.map(work => ({
          value: work.id,
          label: `${work.name}`,
        }))
      })

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const {
      tableColumns,
      perPage,
      currentPage,
      totalWorkplaces,
      dataMeta,
      perPageOptions,
      isSortDirDesc,
      refWorkplaceListTable,

      refetchData,
      fetchWorkplaces,

      updateWorkplace,
      searchQuery,
      sortBy,
      phoneMask,
    } = useReport()

    return {
      currency,
      formatDate,
      reports,

      updateWorkplace,
      searchQuery,
      fetchWorkplaces,
      perfectScrollbarSettings,
      sortBy,
      loadReport,

      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
      beginDate,
      endDate,
      physicianUserId,
      workplaceId,
      tableColumns,
      perPage,
      currentPage,
      totalWorkplaces,
      dataMeta,
      perPageOptions,
      isSortDirDesc,
      refWorkplaceListTable,
      refetchData,
      workplace,
      phoneMask,
      physicianUserOptions,
      workplaceOptions,
      downloadReport,
      downloadPaymentVoucher,
    }
  },
}
</script>

<style lang="scss" scoped>
.text-muted {
  color: #898993!important;
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.panel-physician {
  background-color: rgb(248, 248, 248);
}

label {
  margin-top: 0.2857rem!important;
}
</style>
